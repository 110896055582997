// 免费视频
import request from '../utils/request';
// 获取免费视频列表
export async function getAuditions(params) {
    try {
        return await request({
            url: `/freeVideo`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取视频详情
export async function getAuditionDetail(id) {
    try {
        return await request({
            url: `/freeVideo/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 获取视频评论列表
export async function getAuditionComments(id, current) {
    try {
        return await request({
            url: `/publicComment/comment/${id}?current=${current? current:1}&type=6`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 发布评论
export async function publishComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取视频子级评论
export async function getAuditionChildComments(commentId, page) {
    try {
        return await request({
            url: `/publicComment/commentChildren/${commentId}?current=${page? page :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 发布免费视频评论
export async function publishAuditionDetailComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}
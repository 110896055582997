<template>
  <!-- 视频详情观看 -->
  <div id="app" style="background: #f4f4f4">
    <div class="main">
      <div class="cson-container clearfix">
        <div class="content">
          <div class="title">
            {{ detailInfo.title }}
          </div>
          <div class="detailContent">
            <iframe
              class="detailIframe"
              :src="videoUrl"
              scrolling="no"
              border="0"
              frameborder="no"
              framespacing="0"
              allowfullscreen="true"
            >
            </iframe>
          </div>
          <div class="commentsBelow">
            <div class="panel-title left">视频简介</div>
            <div
              class="briefIntroduction"
              v-html="detailInfo.briefIntroduction"
            ></div>
          </div>
          <div class="commentArea yours" style="position: relative">
            <div
              style="
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                text-align: center;
                line-height: 230px;
                z-index: 99999;
                background: rgba(0, 0, 0, 0.2);
                color: #fff;
              "
              v-if="!userInfo || !userInfo.id"
            >
              请先
              <router-link to="/login">
                <el-button type="primary" size="mini" style="margin: 8px"
                  >登录</el-button
                ></router-link
              >后发表评论
            </div>
            <el-row>
              <el-col :span="2">
                <img
                  class="userInfo avatar"
                  v-if="userInfo && userInfo.id"
                  :src="userInfo.avatar"
                  alt=""
                />
                <img
                  class="userInfo avatar"
                  v-else
                  src="@/assets/company/userLogo.png"
                  alt=""
                />
              </el-col>
              <el-col :span="22">
                <el-row>
                  <el-input
                    type="textarea"
                    v-model="comments"
                    :rows="3"
                    placeholder="写下你的评论..."
                    ref="activityCom"
                  >
                  </el-input>
                </el-row>
                <el-row style="margin-top: 12px">
                  <el-col :span="6">
                    <emotions @chooseEmotion="handleEmotion"></emotions>
                  </el-col>
                  <el-col :offset="14" :span="4" style="text-align: right">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="publishComment"
                      >发表评论</el-button
                    >
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <comments
            v-if="detailInfo.commentNums || detailInfo.commentNums == 0"
            :type="6"
            :comment-total="detailInfo.commentNums"
          ></comments>
        </div>
        <div class="aside">
          <div class="teacher-aside">
            <img
              class="teacherAvatar"
              src="@/assets/images/story/tx2.png"
              alt=""
            />
            <div class="teacherDes">
              <div class="name">Edward Shi</div>
              <div class="des">专注于北美CS求职，FMAG世界级公司</div>
            </div>
          </div>
          <SideAdvertisement></SideAdvertisement>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAuditionDetail, publishComment } from "@/service/audition";
import comments from "@/components/comments/index";
import { mapState } from "vuex";
import emotions from "@/utils/emotions/index";
import SideAdvertisement from "@/components/sideAdvertisement";
export default {
  name: "activityDetail",
  components: { emotions, comments, SideAdvertisement },
  data() {
    return {
      detailInfo: {},
      comments: "",
      total: 0,
      currentPage: 1,
      groupComList: [],
      videoUrl: "",
    };
  },
  methods: {
    getAuditionDetail() {
      getAuditionDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.detailInfo = res.result;
          if (this.videoUrl == "") {
            this.videoUrl = res.result.videoUrl;
          }
        }
      });
    },
    handleEmotion(emotion) {
      this.comments = this.comments + emotion;
    },
    publishComment() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      if (this.comments == "") {
        return this.$message.error("请输入评论内容！");
      }
      const data = {
        content: this.comments,
        superType: 6,
        type: true,
        superId: this.$route.query.id,
        parentId: 0,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.getAuditionDetail();
          this.$bus.$emit("updateCommentList", "update");
          this.comments = "";
        }
      });
    },
    pointGood() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      const data = {
        superType: 6,
        type: false,
        superId: this.$route.query.id,
        parentId: 0,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.closeAll();
          this.$message.success("点赞成功！");
          this.getAuditionDetail();
        } else {
          this.$message.closeAll();
          this.$message.info("已点过赞");
        }
      });
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      const data = {
        superType: 6,
        superId: this.$route.query.id,
        type: false,
        parentId: commentId,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.closeAll();
          this.$message.success("点赞成功！");
          this.getAuditionDetail();
        } else {
          this.$message.closeAll();
          this.$message.info("已点过赞");
        }
      });
    },
  },
  mounted() {
    this.getAuditionDetail();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
#app {
  min-height: 900px;
  padding: 20px 0;
}
.content {
  width: 890px;
  float: left;
}

.aside {
  width: 300px;
  float: right;
}
.aside-course {
  font-size: 0;
  margin-bottom: 10px;
}

.aside-course img {
  width: 100%;
}

.commentArea,
.commentsBelow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px 48px;
  margin-bottom: 12px;
}
.detailIframe {
  width: 100%;
  min-height: 600px;
  border-radius: 4px;
  margin-bottom: 12px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.userAvatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.username {
  font-size: 14px;
  line-height: 36px;
  vertical-align: top;
  margin-left: 12px;
  color: #0075f6;
}
.createtime {
  color: #bebebe;
  font-size: 18px;
}
.textContent {
  margin-top: 14px;
  color: #34495e;
  font-size: 20px;
}
.imageContainer {
  padding-top: 24px;
  padding-bottom: 24px;
}
::v-deep .imageContainer .el-image {
  margin-right: 12px;
  margin-bottom: 12px;
}
.comment-user {
  min-height: 18px;
  vertical-align: top;
}
.comment-user div {
  font-size: 12px;
  line-height: 20px;
  margin-left: 12px;
  cursor: pointer;
  display: inline-block;
}
.comment-user div i {
  vertical-align: middle;
  font-size: 12px;
}
.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.panel-title {
  position: relative;
  font-weight: 500;
  color: #34495e;
  font-size: 14px;
  padding-left: 11px;
  line-height: 20px;
}

.panel-title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}
.paginations {
  text-align: center;
  margin: 20px 0;
}
.el-pagination /deep/ .el-pager li {
  height: 21px;
  line-height: 21px;
  min-width: 22px;
  font-size: 12px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.el-pagination.is-background /deep/ .el-pager li:not(.disabled).active {
  background-color: rgba(250, 100, 0, 1);
}

.el-pagination /deep/ button,
.el-pagination /deep/ span {
  font-size: 12px;
  height: 21px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 50px;
}

.el-pagination .last {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.el-pagination .first {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.noComments {
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
}

.team-cell__hd {
  width: 200px;
  margin-right: 17px;
  float: left;
}
.team-cell:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.team-cell {
  padding: 24px 0;
}

.team-cell-title {
  color: #34495e;
  line-height: 36px;
  font-size: 16px;
  margin-bottom: 4px;
}

.push-time {
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  font-size: 12px;
  margin-left: 12px;
}

.team-cell-desc {
  font-size: 14px;
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  margin-bottom: 4px;
  padding-left: 44px;
}

.team-cell-desc .el-link {
  margin-left: 30px;
}

.team-cell .el-link {
  color: #fa6400;
}

.team-cell__bd {
  position: relative;
}

.team-cell-info .comment-user {
  color: #34495e;
  padding-left: 44px;
}

.childComment .team-cell-info .comment-user {
  color: #34495e;
  padding-left: 30px;
}
.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 15px;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}

.team-cell__footer {
  padding: 15px 20px 0;
}

.team-cell__ed {
  float: left;
}

.team-cell__block {
  position: relative;
  margin-left: 44px;
}

.team-cell__bts {
  text-align: right;
  padding: 10px 0;
}

.team-cell__bts .btn {
  font-size: 14px;
  border-radius: 2px;
  width: 64px;
  line-height: 28px;
  border-color: transparent;
  margin-left: 10px;
}
.secondCommentList {
  width: 95%;
  margin: 12px 0;
  margin-left: 5%;
}
.childComment {
  padding: 6px;
  line-height: 24px;
  .avatar {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 50%;
    vertical-align: top;
  }
  .commentContent {
    color: #222;
    margin-top: 4px;
  }
}
.aside-zx {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 22px 0;
  text-align: center;
}

.aside-zx-tt {
  font-size: 14px;
  color: #34495e;
  margin-bottom: 6px;
  text-align: center;
}
.zx-ewm {
  border-radius: 2px;
  border: 3px solid #0075f6;
  width: 106px;
  height: 106px;
  margin: 0 auto;
  font-size: 0;
}
.briefIntroduction {
  padding: 20px 0;
  font-size: 14px;
  color: #333;
}
.content .title {
  font-size: 24px;
  padding: 12px 0;
}
.teacherAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.teacherDes {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  .name {
    font-size: 14px;
    margin: 4px 0;
  }
  .des {
    font-size: 12px;
    color: #999;
  }
}
.teacher-aside {
  margin-bottom: 8px;
}
.aside-course:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
</style>